<template>
  <div>
    <div class="container-matching-question">
      <div class="table-left">
        <table class="term-table">
          <thead>
            <tr>
              <th v-if="allows_crud"></th>
              <th class="input-number1">N°</th>
              <th class="input-term1">Término 1</th>
              <th
                v-if="allows_crud"
                style="width: 19%"
                v-b-tooltip.top.noninteractive.v-secondary="
                  `${
                    allows_crud
                      ? 'Selecciona el número de opciones correctas'
                      : ''
                  }`
                "
              >
                N° de Opciones
              </th>
              <th
                v-if="
                  allows_crud ||
                  (preview_evaluation && !evaluatee_view && !evaluator_view) ||
                  (evaluatee_view && !finished_test && !evaluator_view)
                "
                class="noprint"
              ></th>
            </tr>
          </thead>
          <MatchingQuestionDraggableOption
            :display_position_left="true"
            :allows_crud="allows_crud"
            :container_instrument_id="container_instrument_id"
            :ids_left_position="ids_left_position"
            :tem_ids_left_position="tem_ids_left_position"
            :Question="Question"
            :preview_evaluation="preview_evaluation"
            :evaluatee_view="evaluatee_view"
            :evaluator_view="evaluator_view"
            :finished_test="finished_test"
            :user_id="user_id"
          >
          </MatchingQuestionDraggableOption>
        </table>
        <b-button
          v-if="allows_crud"
          variant="primary"
          size="sm"
          class="mt-1"
          @click="createdOption(true)"
        >
          + Agregar alternativa
        </b-button>
      </div>
      <div class="table-right">
        <table class="term-table">
          <thead>
            <tr>
              <th v-if="allows_crud"></th>
              <th class="input-number2" v-if="user_id && evaluator_view">
                Respuesta
              </th>
              <th class="input-number2">N°</th>
              <th class="input-term2">Término 2</th>
              <!-- <th v-if="!preview_evaluation" class="input-score noprint">Puntaje</th> -->
              <th
                v-if="
                  allows_crud ||
                  (preview_evaluation && !evaluatee_view && !evaluator_view) ||
                  (evaluatee_view && !finished_test && !evaluator_view)
                "
                class="th-right-btn noprint"
              ></th>
            </tr>
          </thead>
          <MatchingQuestionDraggableOption
            :display_position_left="false"
            :allows_crud="allows_crud"
            :container_instrument_id="container_instrument_id"
            :ids_left_position="ids_left_position"
            :tem_ids_left_position="tem_ids_left_position"
            :Question="Question"
            :preview_evaluation="preview_evaluation"
            :evaluatee_view="evaluatee_view"
            :finished_test="finished_test"
            :evaluator_view="evaluator_view"
            :user_id="user_id"
            @clear_ids_left_position="clearIdsLeftPosition"
            @clear_tem_ids_left_position="clearTemIdsLeftPosition"
          >
          </MatchingQuestionDraggableOption>
        </table>
        <b-button
          v-if="allows_crud"
          variant="primary"
          size="sm"
          class="mt-1"
          @click="createdOption(false)"
        >
          + Agregar alternativa
        </b-button>
      </div>
    </div>
    <div class="container-score">
      <MatchingQuestionScore
        :key="key_matching_question_score_table"
        :Question="Question"
        :allows_crud="allows_crud"
        :evaluator_view="evaluator_view"
        :preview_evaluation="preview_evaluation"
      ></MatchingQuestionScore>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId, toast } from "@/utils/utils";

export default {
  name: "MatchingQuestion",
  components: {
    MatchingQuestionDraggableOption: () =>
      import("./MatchingQuestionDraggableOption"),
    MatchingQuestionScore: () => import("./MatchingQuestionScore"),
  },
  props: {
    Question: {
      type: Object,
      required: true,
    },
    QuestionScore: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          evaluatee: null,
          matching_question: this.Question.id,
          attachments: [],
        };
      },
    },
    allows_crud: {
      type: Boolean,
      required: true,
    },
    preview_evaluation: {
      type: Boolean,
      required: true,
    },
    container_instrument_id: {
      type: String,
    },
    evaluatee_view: {
      type: Boolean,
      default: false,
    },
    evaluator_view: {
      type: Boolean,
      default: false,
    },
    is_running_test: {
      type: Boolean,
      default: false,
    },
    finished_test: {
      type: Boolean,
      default: false,
    },
    // para mostrar el resultado del evaluado al profesor.
    user_id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      question_score: { ...this.QuestionScore },
      ids_left_position: [],
      tem_ids_left_position: [],
      key_matching_question_score_table: 0,
    };
  },
  computed: {
    ...mapGetters({
      matching_question_options: names.NEW_MATCHING_QUESTION_OPTIONS,
    }),
    matchingQuestionOptions() {
      return this.matching_question_options.filter(
        (x) => x.matching_question == this.Question.id
      );
    },
  },
  methods: {
    clearIdsLeftPosition() {
      this.ids_left_position = [];
    },
    clearTemIdsLeftPosition() {
      this.tem_ids_left_position = [];
    },
    showModalQuestion() {
      if (this.allows_crud)
        this.$bvModal.show(
          `edit-title-question-modal-${this.container_instrument_id}`
        );
    },
    createdOption(display_to_the_left) {
      const matching_question_option = this.matchingQuestionOptions.filter(
        (x) => x.display_to_the_left == display_to_the_left
      );
      if (matching_question_option.length > 0)
        this.$store
          .dispatch(names.POST_NEW_MATCHING_QUESTION_OPTION, {
            title: "",
            description: "",
            display_to_the_left: display_to_the_left,
            correct_matches: [],
            order:
              matching_question_option[matching_question_option.length - 1]
                .order + 1,
            matching_question: this.Question.id,
          })
          .then((response) => {
            this.createdMatchingQuestionScore(display_to_the_left, response);
          });
      else
        this.$store
          .dispatch(names.POST_NEW_MATCHING_QUESTION_OPTION, {
            ttitle: "",
            description: "",
            display_to_the_left: display_to_the_left,
            correct_matches: [],
            order: 1,
            matching_question: this.Question.id,
          })
          .then((response) => {
            this.createdMatchingQuestionScore(display_to_the_left, response);
          });
      toast("Se agregó una alternativa");
    },
    async createdMatchingQuestionScore(
      display_to_the_left,
      matching_question_option
    ) {
      for await (let option of this.matching_question_options.filter(
        (x) =>
          x.matching_question == this.Question.id &&
          x.display_to_the_left == !display_to_the_left
      )) {
        let mat_score = {
          score: 0,
          matching_question_unit_1: null,
          matching_question_unit_2: null,
        };
        if (display_to_the_left) {
          mat_score.matching_question_unit_1 = matching_question_option.id;
          mat_score.matching_question_unit_2 = option.id;
        } else {
          mat_score.matching_question_unit_1 = option.id;
          mat_score.matching_question_unit_2 = matching_question_option.id;
        }
        await this.$store.dispatch(
          names.POST_NEW_MATCHING_QUESTION_SCORE,
          mat_score
        );
      }
      this.key_matching_question_score_table++;
    },
  },
  created() {},
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scoped>
.th-right-btn {
  min-width: 44px;
}
.container-matching-question {
  /* margin-top: 15px; */
  margin-bottom: 20px;
  padding-right: 10px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}
.term-table {
  border-collapse: collapse;
}
.input-number1,
.input-number2 {
  min-width: 50px;
}
.input-term1,
.input-term2 {
  width: 400px;
}
.input-score {
  min-width: 100px;
}
/* .table-left {
   margin-right: 30px; 
} */
.term-table th,
.term-table td {
  border: 1px solid #ddd;
  padding: 6px;
  text-align: center;
}
.term-table th {
  background-color: #f2f2f2;
}
@media (max-width: 750px) {
  .table-left,
  .table-right {
    min-width: 500px;
    margin-right: 35px;
  }
  .container-matching-question {
    overflow-x: auto;
    flex-direction: column;
  }
  .table-right {
    margin-top: 35px;
    margin-bottom: 25px;
  }
}
</style>

