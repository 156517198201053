import { render, staticRenderFns } from "./MatchingQuestion.vue?vue&type=template&id=148400d7&scoped=true"
import script from "./MatchingQuestion.vue?vue&type=script&lang=js"
export * from "./MatchingQuestion.vue?vue&type=script&lang=js"
import style0 from "@/utils/rich_text_editor.css?vue&type=style&index=0&id=148400d7&prod&scoped=true&lang=css&external"
import style1 from "./MatchingQuestion.vue?vue&type=style&index=1&id=148400d7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "148400d7",
  null
  
)

export default component.exports